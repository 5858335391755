import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Typography, Alert, CircularProgress, Divider, Grid, Card, CardContent } from '@mui/material';
import { ErrorOutline, ArrowBack, Close, CheckCircle, InfoOutlined, Public, Security, Settings } from '@mui/icons-material';
import llmScanClient from '../../../llmScanClient';  // Adjust the import path as needed
import theme from '../../../theme';

export default function RunScanSummaryModal({
  isSummaryModalOpen,
  setIsSummaryModalOpen,
  llmData,
  llmType,
  probes,
  probeType,
  tenantId,
  username,
  accessToken,
  setScans,
  setIsProbesModalOpen,
  setIsModalOpen,
  llmEnvName
}) {
  const cancelButtonRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let url = '';
    let payload = {};

    try {
      if (llmType === 'ai_policy') {
        const formattedAiPolicyType = encodeURIComponent(llmData.aiPolicyType.replace(/[_/]/g, '-')); // Replace underscores and slashes with hyphens
        if (probeType === 'owasp') {
          url = `/command_scan_guardrails?tenant_id=${tenantId}&username=${username}&model_type=AIPolicy&model_name=${formattedAiPolicyType}&probe_tags=${probes.join(',')}&taxonomy=owasp&probe_type=owasp`;
        } else if (probeType === 'custom') {
          url = `/command_scan_guardrails?tenant_id=${tenantId}&username=${username}&model_type=AIPolicy&model_name=${formattedAiPolicyType}&probe=${probeType}&list_name=${probes.join(',')}&access_token=${accessToken}&probe_type=my_test_suite`;
        } else {
          url = `/command_scan_guardrails?tenant_id=${tenantId}&username=${username}&model_type=AIPolicy&model_name=${formattedAiPolicyType}&probe=${probes.join(',')}&probe_type=custom_probes`;
        }
        if (llmData.generations) {
          url += `&generations=${llmData.generations}`;
        }
        payload = {
          "accessToken": String(accessToken),
          "configId": llmData.aiPolicyType
        };
      } else if (llmType === 'private') {
        url = `/command_scan_private?tenant_id=${tenantId}&username=${username}`;
        if (llmData.generations) {
          url += `&generations=${llmData.generations}`;
        }
        if (probeType === 'owasp') {
          url += `&probe_tags=${probes.join(',')}&taxonomy=owasp&probe_type=owasp`;
        } else if (probeType === 'custom') {
          url += `&probe=${probeType}&list_name=${probes.join(',')}&access_token=${accessToken}&probe_type=my_test_suite`;
        } else {
          url += `&probe=${probes.join(',')}&probe_type=custom_probes`;
        }

        let headerTemplate;
        let payloadTemplate;
        try {
          headerTemplate = JSON.parse(llmData.header_template);
          payloadTemplate = JSON.parse(llmData.payload_template);
        } catch (parseError) {
          setLoading(false);
          setErrorMessage('Invalid JSON format in Header or Payload Template. Please check your input.');
          return;
        }

        payload = {
          url: llmData.url,
          header_template: headerTemplate,
          payload_template: payloadTemplate
        };
      } else {
        const encodedLlmName = encodeURIComponent(llmData.llmName);
        url = `/command_scan?model=${llmData.llmModel}&name=${encodedLlmName}`;
        if (probeType === 'owasp') {
          url += `&probe_tags=${probes.join(',')}&taxonomy=owasp&probe_type=owasp`;
        } else if (probeType === 'custom') {
          url += `&probe=${probeType}&list_name=${probes.join(',')}&access_token=${accessToken}&probe_type=my_test_suite`;
        } else {
          url += `&probe=${probes.join(',')}&probe_type=custom_probes`;
        }
        url += `&llm_env_name=${llmEnvName || ''}&llm_key=${llmData.apiKey || ''}&tenant_id=${tenantId}&username=${username}`;
        if (llmData.generations) {
          url += `&generations=${llmData.generations}`;
        }
        payload = null; // Empty payload for the command_scan
      }

      const response = await llmScanClient.post(url, payload);
      console.log(response.data);
      const responseList = await llmScanClient.get(`/list_runs/${tenantId}`);
      setScans(responseList.data.results); // Update the scans in LlmScanPage
      setIsSummaryModalOpen(false);
      setLoading(false);
    } catch (error) {
      console.error('Error submitting form', error);
      setErrorMessage('There was an error submitting the form. Please check your JSON input.');
      setLoading(false);
    }
  };

  const handleBack = () => {
    setErrorMessage(''); // Reset error message on back
    setIsSummaryModalOpen(false);
    setIsProbesModalOpen(true);
  };

  const handleClose = () => {
    setErrorMessage(''); // Reset error message on close
    setIsSummaryModalOpen(false);
    setIsProbesModalOpen(false);
    setIsModalOpen(false);
  };

  return (
    <Transition.Root show={isSummaryModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden flex justify-center items-center ml-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-[-100%]"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-[-100%]"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl h-full max-h-[53vh] flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto">
                <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                  <div className="flex items-start justify-between">
                    <div className="flex items-center">
                      <ArrowBack
                        onClick={handleBack}
                        className="h-6 w-6 mr-2 cursor-pointer"
                        style={{ color: theme.tmryk_background_color }}
                      />
                      <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>
                        Summary
                      </Dialog.Title>
                    </div>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                        onClick={handleClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <Close className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                  {errorMessage && (
                    <Alert severity="error" className="mb-4" icon={<ErrorOutline fontSize="inherit" />}>
                      {errorMessage}
                    </Alert>
                  )}
                  <Grid container spacing={3}>
                    {llmType === 'ai_policy' && (
                      <Grid item xs={12}>
                        <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 2, ':hover': { boxShadow: 6 } }}>
                          <CardContent>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Security style={{ color: theme.tmryk_background_color }} />
                              </Grid>
                              <Grid item xs>
                                <Typography variant="h6" component="h2" gutterBottom>
                                  LLM Scan Details
                                </Typography>
                                <Typography variant="body1">
                                  <strong>AI Policy Type:</strong> {llmData.aiPolicyType}
                                </Typography>
                                <Typography variant="body1">
                                  <strong>Iterations:</strong> {llmData.generations || 'Default'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    {llmType === 'public' && (
                      <Grid item xs={12}>
                        <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 2, ':hover': { boxShadow: 6 } }}>
                          <CardContent>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Public style={{ color: theme.tmryk_background_color }} />
                              </Grid>
                              <Grid item xs>
                                <Typography variant="h6" component="h2" gutterBottom>
                                  LLM Details
                                </Typography>
                                <Typography variant="body1">
                                  <strong>Select Model:</strong> {llmData.llmModel}
                                </Typography>
                                <Typography variant="body1">
                                  <strong>LLM Name:</strong> {llmData.llmName}
                                </Typography>
                                {/* Conditionally render API Key if it's not null */}
                                {llmData.apiKey && (
                                  <Typography variant="body1">
                                    <strong>API Key:</strong> {llmData.apiKey}
                                  </Typography>
                                )}
                                <Typography variant="body1">
                                  <strong>Iterations:</strong> {llmData.generations || 'Default'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    {llmType === 'private' && (
                      <Grid item xs={12}>
                        <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 2, ':hover': { boxShadow: 6 } }}>
                          <CardContent>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Settings style={{ color: theme.tmryk_background_color }} />
                              </Grid>
                              <Grid item xs>
                                <Typography variant="h6" component="h2" gutterBottom>
                                  LLM Details
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <Typography variant="body1">
                                  <strong>Endpoint URL:</strong> {llmData.url}
                                </Typography>
                                <Typography variant="body1" className="break-words max-w-full">
                                  <strong>Endpoint Header:</strong> {llmData.header_template}
                                </Typography>
                                <Typography variant="body1" className="break-words max-w-full">
                                  <strong>Endpoint Data:</strong> {llmData.payload_template}
                                </Typography>
                                <Typography variant="body1">
                                  <strong>Iterations:</strong> {llmData.generations || 'Default'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 2, ':hover': { boxShadow: 6 } }}>
                        <CardContent>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <InfoOutlined style={{ color: theme.tmryk_background_color }} />
                            </Grid>
                            <Grid item xs>
                              <Typography variant="h6" component="h2" gutterBottom>
                                Selected Scan Type
                              </Typography>
                              <Divider sx={{ my: 1 }} />
                              <Typography variant="body1">{probes.join(', ')}</Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Divider className="my-4" />
                  <div className="flex justify-end space-x-3 p-6 bg-white rounded-b-lg">
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      ref={cancelButtonRef}
                      startIcon={<ArrowBack />}
                      style={{
                        borderColor: theme.tmryk_background_color,
                        color: theme.tmryk_background_color,
                        width: '150px',
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: theme.tmryk_background_color,
                        color: 'white',
                        width: '150px',
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                      }}
                      endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <CheckCircle />}
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? 'Running...' : 'Run Scan'}
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
