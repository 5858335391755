import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  TextField,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
  Alert,
  Typography,
  Slider,
  Box,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import client from "../../../client"; // Adjust the import path as needed
import theme from "../../../theme";
import CreatePolicyScanModal from "./CreatePolicyScanModal";
import RunScanProbesModal from "./RunScanProbesModal";
import RunScanSummaryModal from "./RunScanSummaryModal";

const LLM_TYPES = {
  openai: {
    keyName: "OPENAI_API_KEY",
    models: ["gpt-4", "gpt-3.5-turbo", "gpt-4-turbo-preview"],
  },
  octo: {
    keyName: "OCTO_API_TOKEN",
    models: [
      "meta-llama-3-8b-instruct",
      "meta-llama-3-70b-instruct",
      "mistral-7b-instruct-v0.3",
      "mixtral-8x7b-instruct",
    ],
  },
  replicate: {
    keyName: 'REPLICATE_API_TOKEN',
    models: ['meta/meta-llama-3-70b-instruct', 'meta/llama-2-70b-chat', 'mistralai/mistral-7b-instruct-v0.2'],
  },
  bedrock_claude: {
    keyName: '', 
    models: ['anthropic.claude-3-5-sonnet'],
  },
};

export default function RunScanModal({
  isModalOpen,
  setIsModalOpen,
  setScans,
  tenantId,
  username,
  accessToken,
}) {
  const formRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const [llmType, setLlmType] = useState("ai_policy");
  const [aiPolicyType, setAiPolicyType] = useState("");
  const [configs, setConfigs] = useState([]);
  const [llmEnvName, setLlmEnvName] = useState("");
  const [llmData, setLlmData] = useState({
    llmName: "",
    llmModel: "",
    apiKey: "",
    generations: "",
    aiPolicyType: "",
  });
  const [isProbesModalOpen, setIsProbesModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [probes, setProbes] = useState([]);
  const [probeType, setProbeType] = useState("owasp");
  const [isCreatePolicyModalOpen, setIsCreatePolicyModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [isDefault, setIsDefault] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");

  const fetchConfigs = async () => {
    try {
      const response = await client.get("/v1/rails/configs");
      setConfigs(response.data);
    } catch (error) {
      console.error("Error fetching configs:", error);
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  useEffect(() => {
    setError("");
  }, [llmType]);

  const handleInputChange = (event) => {
    setLlmData({ ...llmData, [event.target.name]: event.target.value });
  };

  const handleTypeChange = (event) => {
    setLlmType(event.target.value);
    setError("");
  };

  const handleAiPolicyChange = (event) => {
    setAiPolicyType(event.target.value);
    setLlmData({ ...llmData, aiPolicyType: event.target.value });
    setError("");
  };

  const handleLlmModelChange = (event) => {
    const selectedLlmModel = event.target.value;
    setLlmData({
      ...llmData,
      llmModel: selectedLlmModel,
      llmName: selectedLlmModel === 'bedrock_claude' ? 'anthropic.claude-v2' : '',
    });
    setLlmEnvName(LLM_TYPES[selectedLlmModel]?.keyName || "");
    setError("");
  };

  const handleSliderChange = (event, newValue) => {
    if (newValue <= 10) {
      setLlmData({ ...llmData, generations: newValue });
      setError("");
    }
  };

  const handleDefaultChange = (event) => {
    setIsDefault(event.target.checked);
    if (event.target.checked) {
      setLlmData({ ...llmData, generations: "" });
    }
    setError("");
  };

  const validateFields = () => {
    if (llmType === "ai_policy" && !aiPolicyType)
      return "Please select AI Policy Type";
    if (llmType === "public") {
      if (!llmData.llmModel) return "Please select a Model";
      if (!llmData.llmName) return "Please enter LLM Name";
      if (!llmData.apiKey && llmData.llmModel !== 'bedrock_claude') return "Please enter API Key";
    }
    if (llmType === "private") {
      if (!llmData.url) return "Please enter Endpoint URL";
      if (!llmData.header_template) return "Please enter Endpoint Header";
      if (!llmData.payload_template) return "Please enter Endpoint Data";
    }
    return "";
  };

  const handleNext = () => {
    const errorMessage = validateFields();
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setError("");
    setIsModalOpen(false);
    setIsProbesModalOpen(true);
  };

  const handlePolicyCreationSuccess = async (newPolicyName) => {
    await fetchConfigs();
    setAiPolicyType(newPolicyName);
    setSuccessMessage(
      `Policy '${newPolicyName}' created successfully! You can now start scanning using your new policy.`
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setError("");
  };

  return (
    <>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden flex justify-center items-center ml-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-[-100%]"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-[-100%]"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen max-w-6xl h-full ${
                    llmType === "ai_policy"
                      ? "max-h-[65vh]"
                      : llmType === "private"
                      ? "max-h-[94vh]"
                      : "max-h-[80vh]"
                  } flex flex-col bg-white shadow-xl rounded-lg overflow-y-auto`}
                >
                  <div className="px-4 pt-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <Dialog.Title
                        className="text-xl font-semibold"
                        style={{ color: theme.tmryk_background_color }}
                      >
                        Run LLM Scan
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2"
                          onClick={handleCloseModal}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 px-4 py-6 sm:px-6 overflow-y-auto">
                    {isCreatePolicyModalOpen ? (
                      <CreatePolicyScanModal
                        setIsCreatePolicyModalOpen={setIsCreatePolicyModalOpen}
                        setAiPolicyType={setAiPolicyType}
                        onSuccess={handlePolicyCreationSuccess}
                      />
                    ) : (
                      <form ref={formRef} className="space-y-6">
                        {successMessage && (
                          <Alert severity="success" className="mt-4">
                            {successMessage}
                          </Alert>
                        )}
                        <FormControl component="fieldset">
                          <FormLabel
                            component="legend"
                            style={{ color: "Black" }}
                          >
                            Select LLM Type
                          </FormLabel>
                          <RadioGroup
                            aria-label="llmType"
                            name="llmType"
                            value={llmType}
                            onChange={handleTypeChange}
                            row
                          >
                            <FormControlLabel
                              value="ai_policy"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: theme.tmryk_background_color,
                                    },
                                  }}
                                />
                              }
                              label="AI Policy"
                            />
                            <FormControlLabel
                              value="public"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: theme.tmryk_background_color,
                                    },
                                  }}
                                />
                              }
                              label="Public LLM"
                            />
                            <FormControlLabel
                              value="private"
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: theme.tmryk_background_color,
                                    },
                                  }}
                                />
                              }
                              label="Private Endpoint"
                            />
                          </RadioGroup>
                        </FormControl>

                        <div className="space-y-4">
                          {llmType === "ai_policy" && (
                            <div className="flex items-center space-x-4">
                              <FormControl fullWidth>
                                <InputLabel className="text-gray-700">
                                  Select AI Policy Type
                                </InputLabel>
                                <Select
                                  name="aiPolicyType"
                                  label="Select AI Policy Type"
                                  value={aiPolicyType}
                                  onChange={handleAiPolicyChange}
                                  className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                >
                                  {configs.map((config, index) => (
                                    <MenuItem key={index} value={config.id}>
                                      {config.id}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setIsCreatePolicyModalOpen(true)}
                                style={{
                                  backgroundColor: theme.tmryk_background_color,
                                  color: "white",
                                  width: "180px",
                                  height: "48px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  boxShadow:
                                    "0 3px 5px 2px rgba(84, 130, 78, .3)",
                                }}
                              >
                                Create AI Policy
                              </Button>
                            </div>
                          )}

                          {llmType === "public" && (
                            <div className="space-y-4">
                              <FormControl fullWidth>
                                <InputLabel className="text-gray-700">
                                  Select Model
                                </InputLabel>
                                <Select
                                  name="llmModel"
                                  label="Select Model"
                                  value={llmData.llmModel}
                                  onChange={handleLlmModelChange}
                                  className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                >
                                  {Object.keys(LLM_TYPES).map((key) => (
                                    <MenuItem key={key} value={key}>
                                      {key}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              {llmData.llmModel && (
                                <FormControl fullWidth>
                                  <InputLabel className="text-gray-700">
                                    Select LLM
                                  </InputLabel>
                                  <Select
                                    name="llmName"
                                    label="Select LLM"
                                    value={llmData.llmName}
                                    onChange={handleInputChange}
                                    className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                                  >
                                    {LLM_TYPES[llmData.llmModel].models.map(
                                      (model, index) => (
                                        <MenuItem key={index} value={model}>
                                          {model}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              )}

                              {/* Conditionally show API Key field if not using bedrock_claude */}
                              {llmData.llmModel !== 'bedrock_claude' && (
                                <TextField
                                  label="API Key"
                                  name="apiKey"
                                  value={llmData.apiKey}
                                  onChange={handleInputChange}
                                  fullWidth
                                  className={`w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500`}
                                />
                              )}
                            </div>
                          )}

                          {llmType === "private" && (
                            <div className="space-y-4">
                              <Typography variant="body2" color="textSecondary">
                                Please enter your Private Endpoint URL here
                              </Typography>
                              <TextField
                                label="Endpoint URL"
                                name="url"
                                value={llmData.url}
                                onChange={handleInputChange}
                                fullWidth
                                className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                              <Typography variant="body2" color="textSecondary">
                                Enter your endpoint's header, Example:{" "}
                                {
                                  '{ "Authorization": "Bearer YOUR_TOKEN", "Content-Type": "application/json" }'
                                }
                              </Typography>
                              <TextField
                                label="Endpoint Header"
                                name="header_template"
                                value={llmData.header_template}
                                onChange={handleInputChange}
                                fullWidth
                                className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                              <Typography variant="body2" color="textSecondary">
                                For your payload template, replace where you
                                inject user prompts with exactly this text
                                ("prompt").
                                <br />
                                Example:{" "}
                                {
                                  '{"messages": [{"role": "user","content": "prompt"}]}'
                                }
                              </Typography>
                              <TextField
                                label="Endpoint Data"
                                name="payload_template"
                                value={llmData.payload_template}
                                onChange={handleInputChange}
                                fullWidth
                                className="w-full bg-white border-2 border-green-700 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                            </div>
                          )}

                          <div className="flex flex-col space-y-2">
                            <Typography variant="subtitle1">
                              Iterations
                            </Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isDefault}
                                  onChange={handleDefaultChange}
                                  style={{
                                    color: theme.tmryk_background_color,
                                  }}
                                />
                              }
                              label={
                                <Typography variant="body2">Default</Typography>
                              }
                            />
                            <Typography variant="body2" color="textSecondary">
                              For the most optimal results, you can select
                              Default
                            </Typography>
                            <Box sx={{ width: 1060, ml: 2 }}>
                              <Slider
                                value={isDefault ? 1 : llmData.generations}
                                onChange={handleSliderChange}
                                min={1}
                                max={50}
                                marks={[
                                  { value: 1, label: "1" },
                                  { value: 5, label: "5" },
                                  { value: 10, label: "10" },
                                  { value: 50, label: "50" },
                                ]}
                                step={1}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => value}
                                disabled={isDefault}
                                sx={{
                                  color: theme.tmryk_background_color,
                                  "& .MuiSlider-thumb": {
                                    backgroundColor:
                                      theme.tmryk_background_color,
                                  },
                                  "& .MuiSlider-track": {
                                    backgroundColor:
                                      theme.tmryk_background_color,
                                  },
                                  "& .MuiSlider-mark": {
                                    backgroundColor: "white",
                                  },
                                }}
                              />
                              {llmData.generations > 10 && (
                                <Tooltip title="To increase the limit beyond 10 iterations, a payment is required.">
                                  <div>
                                    <Slider
                                      value={50}
                                      min={10}
                                      max={50}
                                      marks={[
                                        { value: 10, label: "10" },
                                        { value: 50, label: "50" },
                                      ]}
                                      valueLabelDisplay="auto"
                                      disabled
                                      sx={{
                                        color: theme.tmryk_background_color,
                                        "& .MuiSlider-thumb": {
                                          backgroundColor:
                                            theme.tmryk_background_color,
                                        },
                                        "& .MuiSlider-track": {
                                          backgroundColor:
                                            theme.tmryk_background_color,
                                        },
                                        "& .MuiSlider-mark": {
                                          backgroundColor: "white",
                                        },
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                              Select the number of Iterations
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Note: To increase the limit beyond 10 iterations,
                              a payment is required.
                            </Typography>
                          </div>
                        </div>
                        {error && (
                          <Alert severity="error" className="mt-4">
                            {error}
                          </Alert>
                        )}
                        <div className="flex justify-end space-x-3 p-6 bg-white rounded-b-lg">
                          <Button
                            variant="outlined"
                            onClick={handleCloseModal}
                            ref={cancelButtonRef}
                            style={{
                              borderColor: theme.tmryk_background_color,
                              color: theme.tmryk_background_color,
                              width: "150px",
                              height: "48px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textTransform: "none",
                              fontWeight: "bold",
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: theme.tmryk_background_color,
                              color: "white",
                              width: "150px",
                              height: "48px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textTransform: "none",
                              fontWeight: "bold",
                              boxShadow: "0 3px 5px 2px rgba(84, 130, 78, .3)",
                            }}
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        </div>
                      </form>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <RunScanProbesModal
        isProbesModalOpen={isProbesModalOpen}
        setIsProbesModalOpen={setIsProbesModalOpen}
        setProbes={setProbes}
        setProbeType={setProbeType}
        setIsSummaryModalOpen={setIsSummaryModalOpen}
        setIsModalOpen={setIsModalOpen}
        aiPolicyType={aiPolicyType} // Pass the aiPolicyType here
      />

      <RunScanSummaryModal
        isSummaryModalOpen={isSummaryModalOpen}
        setIsSummaryModalOpen={setIsSummaryModalOpen}
        llmData={llmData}
        llmType={llmType}
        probes={probes}
        probeType={probeType}
        tenantId={tenantId}
        username={username}
        accessToken={accessToken}
        setScans={setScans}
        setIsProbesModalOpen={setIsProbesModalOpen}
        setIsModalOpen={setIsModalOpen}
        llmEnvName={llmEnvName} // Pass the environment name
      />
    </>
  );
}
